@import "simpledotcss/simple.css";

a.home {
  border-width: 0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}

aside.avatar {
  border: none !important;
  width: 50% !important;
  background-color: transparent !important;
}
@media only screen and (max-width: 720px) {
  aside.avatar {
    width: 100% !important;
  }
}

nav.pagination {
  display: flex;
  justify-content: space-between;
}

article.link h2:first-child {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

article.link header p {
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
}

article.link p.link_url {
  font-size: 1rem;
}

body > footer {
  margin-top: 3rem;
}
body > footer p {
  margin: 1rem 0;
}

pre.debugger {
  font-size: 1rem;
}

nav.top_navigation {
  margin: 1rem 0;
}
